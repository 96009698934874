.container {
  margin-top: 20px;
  font-size: 14px;
}
.head {
  border-bottom: 1px solid #ccc;
}

.paid-cell {
  color: #147618;
  padding: 5px;
  background-color: #d7f7c2;
  border-radius: 10px;
}