@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: #f7e4d9;
  font-size: 14px;
  color: #495867;
}

.app-root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
