.nav-wrapper {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f5b7a6;
    padding: 20px 10px;
}

.nav-wrapper span {
    color: #495867;
    font-size: 16px;
    font-weight: 700;
    margin-left: 2rem;
    cursor: pointer;
}