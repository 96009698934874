.card {
  width: 500px;
  margin: 20px;
  background-color: #f7e4d9;
  border: unset;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
}

.add-to-cart-button {
  width: 12rem;
  background-color: rgb(73, 88, 103);
  color: white;
  border: unset;
  border-radius: 20px;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 15px;
  font-size: 16px;
}

.products-intro {
  width: 100%;
  background-color: #cce4f2;
  height: 16rem;
  display: flex;
  flex-direction: column;
}

.products-intro h1 {
  font-size: 3.8rem;
  font-weight: bold;
  margin-left: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.products-intro span {
  font-size: 20px;
  margin-left: 5rem;
  padding-bottom: 2rem;
}

.product-price {
  margin-top: auto;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .card {
    max-width: unset !important;
  }
  .products-intro {
    height: auto;
  }
  .products-intro span {
    margin-left: 1rem;
  }
}
