.day-picker-main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.back-to-services {
  font-weight: bold;
  font-size: 18px;
  margin: 0 10px;
}

.day-picker-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.text-section {
  display: flex;
  flex-direction: column;
  line-height: 25px;
}

.description {
  line-height: 40px;
  margin-top: 20px;
  margin-right: 20px;
  width: 400px;
  word-spacing: inherit;
}

.day-picker-wrapper span {
  font-size: 17px;
}

.day-picker-button {
  border: 1px solid #f3f9ff;
  border-radius: 10px;
  padding: 10px 30px;
  font-weight: bold;
  margin: 10px;
  color: #0d69fb;
  width: 100%;
  background-color: #f3f9ff;
}

.day-picker-button:hover {
  background-color: #c3c9ce;
  border: 1px solid #f3f9ff;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
}

.button-wrapper span {
  font-weight: bold;
}

.day-picker-button-next {
  border: 1px solid #f3f9ff;
  border-radius: 10px;
  padding: 10px 30px;
  font-weight: bold;
  margin: 10px 2px;
  color: white;
  width: 100%;
  background-color: #0d69fb;
}
.day-picker-button-next:hover {
  background-color: #0000ff;
}

.day-picker-button-next:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #80878fff;
  font-weight: bold;
}

.days-out-section {
  width: 250px;
  height: 230px;
  overflow: auto;
  margin-bottom: 10px;
  margin-right: 30px;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 1000px) {
  .description {
    line-height: unset;
    margin: unset;
  }
  .day-picker-wrapper {
    flex-direction: column;
  }
}
