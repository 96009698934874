.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
}

.small-screen-img {
  display: none;
}

.home-wrapper button {
  width: 12rem;
  background-color: rgb(73, 88, 103);
  color: white;
  border: unset;
  border-radius: 20px;
  align-self: center;
  padding: 12px 15px;
  font-size: 16px;
}

/*.home-wrapper img {*/
/*  width: 30rem;*/
/*  height: 30rem;*/
/*  margin: 3rem 7rem;*/
/*}*/

.wrapper-first-section {
  background-color: #cce4f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
}

.wrapper-first-section h1 {
  font-size: 48px;
  font-weight: bold;
  color: #495867;
  margin-bottom: 20px;
}

.wrapper-first-section span {
  font-size: 20px;
  font-weight: 400;
  color: #495867;
  margin-bottom: 20px;
}

.wrapper-second-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.wrapper-second-section div {
  display: flex;
  flex-direction: column;
  margin: 45px 30px;
}

.wrapper-second-section div p {
  font-size: 20px;
  margin: 1.5rem 0;
}

.wrapper-second-section h1 {
  font-size: 30px;
  font-weight: bold;
  align-self: center;
}

.wrapper-second-section img {
  width: 450px;
  height: inherit;
  margin: 45px 30px;
}

.wrapper-third-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #cce4f2;
  width: 100%;
}

.wrapper-third-section div {
  display: flex;
  flex-direction: column;
  margin: 45px 30px;
}

.wrapper-third-section div p {
  font-size: 20px;
  margin: 1.5rem 0;
}

.wrapper-third-section h1 {
  font-size: 30px;
  font-weight: bold;
  align-self: center;
}

.wrapper-third-section img {
  width: 450px;
  height: inherit;
  margin: 45px 30px;
}

.wrapper-fifth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  background-color: #cce4f2;
  width: 100%;
}
.wrapper-fifth-section h1 {
  font-weight: bold;
  font-size: 36px;
}

/*.wrapper-fifth-section div {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  flex-wrap: wrap;*/
/*  align-items: baseline;*/
/*  justify-content: center;*/
/*}*/
.wrapper-fifth-section div p {
  font-size: 16px;
  width: 250px;
}

.wrapper-fifth-section div div {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: auto;
  margin-top: 10px;
}

.wrapper-fifth-section div img {
  width: 250px;
  height: inherit;
}

.wrapper-seventh-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.wrapper-seventh-section div {
  display: flex;
  flex-direction: column;
  margin: 45px 30px;
}

.wrapper-seventh-section div p {
  font-size: 20px;
  margin: 1.5rem 0;
}

.wrapper-seventh-section h1 {
  font-size: 30px;
  font-weight: bold;
}

.wrapper-seventh-section img {
  width: 450px;
  height: 450px;
  margin: 45px 30px;
}

.wrapper-seventh-section div div {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.wrapper-seventh-section div div span {
  margin-right: 20px;
}

@media screen and (max-width: 1000px) {
  .home-wrapper img {
    width: 300px;
    height: 300px;
    margin: 15px;
  }
  .wrapper-first-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 10px 30px;
  }
  .wrapper-first-section h1 {
    font-size: 30px;
    font-weight: bold;
    color: #495867;
    margin-bottom: 20px;
  }
  .wrapper-second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 10px 30px;
    justify-content: center;
  }
  .wrapper-second-section div {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  .wrapper-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #cce4f2;
  }

  .wrapper-third-section div {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  .wrapper-third-section div p {
    font-size: 20px;
  }

  .wrapper-third-section h1 {
    font-size: 30px;
    font-weight: bold;
  }

  .wrapper-fifth-section h1 {
    font-size: 30px;
    margin: 15px;
  }

  .wrapper-fifth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #cce4f2;
    margin: 0;
    padding: 10px 0;
    width: 100%;
  }

  .wrapper-fifth-section div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .wrapper-seventh-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .wrapper-seventh-section div {
    display: flex;
    flex-direction: column;
    margin: 15px;
    align-items: center;
  }
  .wrapper-seventh-section img {
    width: 300px;
  }
  .small-screen-img {
    display: block;
  }
  .big-screen-img {
    display: none;
  }
}
