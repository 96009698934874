.terms-wrapper {
  padding: 50px;
  background-color: #cce4f2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}

.terms-wrapper h1 {
  font-size: 56px;
  font-weight: bold;
  margin: 10px 0;
}

.terms-wrapper h2 {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0;
}

.terms-wrapper p {
  font-weight: 500;
  margin: 10px 0;
  font-size: 16px;
  line-height: 30px;
}

.terms-wrapper ul li {
  font-weight: 500;
  line-height: 30px;
  margin: 10px 0;
  font-size: 16px;
}
