.userInfo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 400px;
}

.userInfo-wrapper input {
  width: 100%;
  height: 40px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.userInfo-email {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 100%;
  margin: 30px 0;
}

.userInfo-fullName {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 100%;
}

.userInfo-wrapper button {
  color: white;
  width: 100%;
  background-color: #0d69fb;
  height: 40px;
  margin: 30px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
}

.userInfo-wrapper button:hover {
  background-color: #0000ff;
}
.userInfo-wrapper button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #80878FFF;
  font-weight: bold;
}

.error-text {
  color: #cb3c3cff;
  font-size: 12px;
}

.contact-info {
  font-weight: bold;
  font-size: 18px;
}