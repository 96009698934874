.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.success-title {
  margin: 30px 0;
  color: #539131;
  font-weight: bold;
}

.cancel-title {
  margin: 30px 0;
  color: #d02d2d;
  font-weight: bold;
}

.wrapper p {
  font-weight: bold;
  margin: 10px 5px;
}

.wrapper button {
  border: 1px solid #f3f9ff;
  border-radius: 10px;
  padding: 10px 30px;
  font-weight: bold;
  margin: 10px 2px;
  color: white;
  width: 200px;
  background-color: #0d69fb;
}

.wrapper button:hover {
  background-color: #0000ff;
}

.email {
  color: #0d69fb;
  cursor: pointer;
}
